import React, {useState} from 'react';
import Sound from 'react-sound';
import djoko from "../Assets/Bellaire - Paris City Jazz.mp3"
import { BsFillPlayFill, BsStopFill } from "react-icons/bs";

const PlaySound = (
    HandleSongLoading,
    handeSongPlaying,
    handleSongFinishedPlaying
) => {
    const [isPlaying, setIsPlaying] = useState(false);
    return (
        <div>
            <button className="icon-colour home-social-icons" onClick={() => setIsPlaying(!isPlaying)}>{!isPlaying ? <BsFillPlayFill /> : <BsStopFill />}
            </button>
            <Sound
            url={djoko}
            playStatus={
                isPlaying ? Sound.status.PLAYING : Sound.status.PAUSED
            }
            onLoading={HandleSongLoading}
            onPlaying={handeSongPlaying}
            onFinishedPlaying={handleSongFinishedPlaying}
            loop={true}
            ></Sound>
        </div>
    );
};

export default PlaySound;
