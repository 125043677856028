import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import cantu from "../../Assets/Projects/cantu.png"
import haier from "../../Assets/Projects/haier.png"
import tenax  from "../../Assets/Projects/tenax.png"
import optimo  from "../../Assets/Projects/optimo.png"
import inoptim from "../../Assets/Projects/inoptim.png"
import github from "../../Assets/Projects/github.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="textColor">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={cantu}
              isBlog={false}
              title="Pallacanestro Cantù"
              description="Complete graphical redesign of the main site of Pallacanestro Cantù basket, one of the main teams in Serie A of italian basketball."
              link="https://pallacanestrocantu.com/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={haier}
              isBlog={false}
              title="Haier Spares EU"
              description="Work on the front-end section of the offial e-commerce of Haier domestic appliances in Europe."
              link="https://www.haierspares.eu/it/home"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={tenax}
              isBlog={false}
              title="Tenax Portal"
              description="Worked on a completely new, wordpress based portal, for internal usage at Tenax SPA, the project is still evolving and used daily at the company with great results."
              link="https://www.tenax.net/it/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={optimo}
              isBlog={false}
              title="Optimo IOT"
              description="Exprience with an AWS cloud based software developed at Optimo IOT, used to track data from machines with a proprietary raspberry based gateway, i worked on the frontend of the configurator using vue.js."
              link="https://www.optimoiot.it/"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={inoptim}
              isBlog={false}
              title="Inopera"
              description="Worked on the back-end of a c# based MES used by 250 companies, using C# .NET and asp.NET and working with databases."
              link="https://github.com/simone-perego/Vue.js-Spacex-Launches"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={github}
              isBlog={false}
              title="Spacex Launches"
              description="Personal Project, a site to show Spacex Launches with details of every ship, developed with vue.js."
              link="https://github.com/simone-perego/Vue.js-Spacex-Launches"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={github}
              isBlog={false}
              title="Bitcoin Wallet"
              description="Personal Poject, a site to convert criptocurrency amount in any currency, developed with React.js"
              link="https://github.com/simone-perego/React-Stella-Bitcoin-Wallet"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
