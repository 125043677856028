import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import homeLogo0 from "../../Assets/home-main.png";
import homeLogo1 from "../../Assets/home-main1.png";
import homeLogo2 from "../../Assets/home-main2.png";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  function randomNumberInRange(min, max) {
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    if(num == 0)
      return homeLogo0
    if(num == 1)
      return homeLogo1
    if(num == 2)
      return homeLogo2
  }
  let homeLogo = "homeLogo" + randomNumberInRange(0, 2)
  return (
    <section>
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row>
            <Col md={6} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                {/* <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span> */}
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name letter"> SIMONE PEREGO</strong>
              </h1>

              <div className="letter" style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col className="img-fluid" md={6} style={{ paddingBottom: 20}}>
              <img
                src={randomNumberInRange(0, 0)}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              /> 
            </Col>
            </Row>
        </Container>
      </Container>
      {/* <Home2 /> */}
    </section>
  );
}

export default Home;


